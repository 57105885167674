import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/mdx-template.js";
import Link from '../components/link.js';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Hi there! I’m Easton.`}</h1>
    <p>{`I’m a software engineer based in Salt Lake City, UT. I’ve been lucky enough to have had a variety of unique experiences within the last decade that I cherish (see `}<Link to="/#timeline" mdxType="Link">{`timeline`}</Link>{`), and I look forward to make a splash in the next. I landed in software development in 2018 and haven’t looked back since. I’m heavily focused in JavaScript and the frontend at the moment. Building a frontend with a codebase that is readable, organized, and scalable for devs to work with is just as important to me as creating an interface that is clean and intuitive for the end user.`}</p>
    <p>{`I'm continually striving to sharpen my dev skills and to make bigger impacts on projects. And I’m thrilled to have landed in a career that provides an outlet for continuous learning, problem solving, creation and collaboration.`}</p>
    <blockquote>
      <p parentName="blockquote"><em parentName="p">{`P.S. I'm currently available and seeking a full-time or contract position as a frontend engineer.`}</em></p>
      <ul parentName="blockquote">
        <li parentName="ul"><em parentName="li"><a href="/easton-gorishek-resume.pdf" target="_blank">{`Resume`}</a>{` - View my resume`}</em></li>
        <li parentName="ul"><em parentName="li"><a parentName="em" {...{
              "href": "/contact"
            }}>{`Contact`}</a>{` - Let's connect!`}</em></li>
      </ul>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      